<template>
  <div class="self-auto pr-2 md:pr-4 lg:pr-8 pb-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3">
    <router-link
      v-if="isUserActive && isAvailable"
      :to="link"
      class="flex cursor-pointer"
    >
      <div class="bg-white h-full w-full rounded-lg shadow-md p-4 flex flex-wrap">
        <div class="self-start w-full">
          <p class="text-left">Вопросы для самоконтроля к теме: </p>
          <h2 class="text-2xl pt-2 font-medium text-gray-700 max-w-lg leading-7 mx-auto text-left hover:text-primary-500">{{ title }}</h2>
        </div>
        <div class="self-end w-full">
          <ProgressCircle 
            :partsDone="pGot"
            :partsTotal="pTotal"
            :drawLable="haveBeenDone"
            :lable1="l1"
            :lable2="l2"
          />
          <p class="text-md mx-auto text-secondary font-semibold">Эксперт: {{ expert }}</p>
          <p class="pt-2 text-sm text-gray-700 font-semibold mx-auto">
            Последний результат:<br>
            <span v-bind:class="resColor">{{ lastResult }}</span>
          </p>
          <SolidButton :title="btnTitle" :link="link" class="w-full"/>
        </div>
      </div>
    </router-link> 
    <div div v-if="!isUserActive || !isAvailable"
          class="bg-white h-full w-full rounded-lg shadow-md p-4 flex flex-wrap">
        <div class="self-start w-full">
          <div class="block w-full text-primary-500 pb-2 text-right text-sm font-semibold">
          Прохождение теста доступно только зарегистрированным пользователям
        </div>
          <p class="text-left">Вопросы для самоконтроля к теме: </p>
          <h2 class="text-2xl pt-2 font-medium text-gray-700 max-w-lg leading-7 mx-auto text-left">{{ title }}</h2>
        </div>
        <div class="self-end w-full">
          <div class="relative">
            <ProgressCircle 
              :partsDone="0"
              :partsTotal="1"
              :drawLable='false'
              :lable1="''"
              :lable2="''"
            />
            <div class="text-gray-400 text-6xl flex justify-center items-center absolute top-0 left-0 w-full h-full">
                <font-awesome-icon icon="lock" class="align-middle" />
            </div>
          </div>
          <p class="text-md mx-auto text-secondary font-semibold">Эксперт: {{ expert }}</p>
          <BlockedButton :title="btnTitle" class="w-full"/>
        </div>
      </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import ProgressCircle from '@/components/_partials/ProgressCircle.vue'
import SolidButton from '@/components/_partials/SolidButton.vue'
import BlockedButton from '@/components/_partials/BlockedButton.vue'

export default {
  name: 'TestCard',
  components: {
    ProgressCircle,
    SolidButton,
    BlockedButton,
  },
  props: {
    test: Object, 
    title: String,
    expert: String,
    link: [String, Object],
    pointsGot: Number,
    pointsTotal: Number,
    isAvailable: Boolean,
  },
  computed: {
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }),
    haveBeenDone(){
      if (typeof this.pointsGot !== 'undefined')
        return true;
      return false;
    },
    pGot(){
      if(typeof this.pointsGot !== 'undefined')
        return this.pointsGot;
      return 0;
    },
    pTotal(){
      if(typeof this.pointsGot !== 'undefined')
        return this.pointsTotal;
      return 1;
    },
    btnTitle(){
      if (this.haveBeenDone)
          return 'Пройти тест заново'
      else
          return 'Начать выполнение теста'
    },
    l1(){
      if (this.haveBeenDone)
          return 'правильных'
      else
          return ''
    },
    l2(){
      if(this.haveBeenDone)
          return 'ответов'
      else
          return ''
    },
    lastResult(){
      if(this.haveBeenDone) {
        var str = this.pointsGot + ' из '+  this.pointsTotal + ' баллов';
        return str;
      }   
      return 'Тест еще не начат'
    },
    resColor(){
      if(this.haveBeenDone) {
        var str = 'text-primary-600';
        return str;
      }   
      return 'text-gray-600 font-normal'
    }
  }
}
</script>
<style>
</style>
