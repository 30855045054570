<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Content -->
    <div v-if="!isLoading" class="flex flex-wrap items-stretch mt-9" key="content">
      <TestCard
        v-for="test in tests"
        :key="test.id"
        :title="test.name"
        :expert="test.expert"
        :link="testUrl + test.id"
        :pointsGot="test.pointsDone" 
        :pointsTotal="test.pointsTotal"
        :isAvailable="test.isPublic || isUserHaveAccess"
      />
    </div>
  </fade-transition> 
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

import TestCard from '@/components/tests/TestCard.vue'

export default {
  name: 'Tests',
  components: {
    FadeTransition,
    LoadingScreen,
    TestCard
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      tests: 'tests/getFilteredTests',
      isUserHaveAccess: 'isUserHaveAccess'
    }),
    testUrl: function() {
      return '/' + this.$route.params.therapyarea_url + '/tests/';
    }
  },
  methods: {
    ...mapActions({
      getTherapyAreaTests: 'tests/getTherapyAreaTests'
    })
  },
  created: function() {
    this.getTherapyAreaTests(this.$route.params.therapyarea_url)
      .then(response => {
        this.isLoading = false
      })
      .catch()
    }
}
</script>