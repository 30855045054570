<template>
  <div id="circle-bar" class="w-full m-auto sm:w-56" >
    <svg width="208px" height="208px" style="display:inline-block" viewbox="0 0 100% 100%">
      <circle r="70px" cx="50%" cy="50%"
        fill="none"
        stroke="grey"
        stroke-width="13%"
        stroke-opacity=".25"
      />
      <circle r="70px" cx="50%" cy="50%"
        fill="none"
        stroke="#00afd3"
        v-bind:stroke-dasharray= "progressLength+' '+space"
        stroke-width="13%"
      />
      <text v-if="drawLable" x="50%" y="50%" fill="#5b2d87" text-anchor="middle" dy="0" font-weight="700" font-size="30">{{ progress }}%</text>
      <text x="50%" y="50%" font-family="arial" fill="#5b2d87" text-anchor="middle" dy="17" font-size="13">{{ lable1 }}</text>
      <text x="50%" y="50%" fill="#5b2d87" stroke="none" text-anchor="middle" dy="30" font-size="13">{{ lable2 }}</text>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'ProgressCircle',
  props: {
    partsDone: Number,
    partsTotal: Number,
    drawLable: Boolean,
    lable1: String,
    lable2: String
  },
  computed: {
    progress() {
      return Math.round(100.0 / this.partsTotal * this.partsDone)
    },
    progressLength() {
      return 70 * 2 * 3.14 / this.partsTotal * this.partsDone
    },
    space() {
      return 70 * 2 * 3.14
    }
  }
}
</script>

<style>
</style>
